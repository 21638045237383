import React from "react";
import logoimg from "../Assets/Landing/Logoimg.png";
import odooLogo from "../Assets/Landing/Odoo_logo.png";
import box from "../Assets/Landing/box.png";

import OurWorks from "../Components/Landing/OurWorks";
import OurServices from "../Components/Landing/OurServices";
// import Numbers from "../Components/Landing/Numbers";
import Why from "../Components/Landing/Why";
import Contact from "../Components/Landing/Contact";
import Footer from "../Components/Landing/Footer";

export default function Landing() {
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    if (targetId === 'top') {
      window.scrollTo({ 
        top: 0,
        behavior: "smooth",
        duration: 500
      });
      return;
    }

    const element = document.getElementById(targetId);
    if (element) {
      const navbarHeight = 100; // Height of the fixed navbar
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
        duration: 500
      });
    }
  };
  return (
    <div id="main" className="w-full min-h-screen bg-[#070226] relative overflow-hidden">
      {/*---------------------------------------------------------------------------Hero Section------------------------------------------------------------------------------*/}

      {/* Top Gradient */}
      <div className="absolute top-0 left-0 w-full h-[250px] bg-gradient-to-b from-[#15F5BA] to-[#211951]/10 opacity-15 pointer-events-none z-10"></div>

      {/* Radial Gradient and Box Background - Centered */}
      <div className="absolute top-[300px] left-1/2 transform -translate-x-1/2 flex items-center justify-center pointer-events-none z-0">
        <div
          className="w-[1400px] h-[1400px] rounded-full animate-gradientOpacity"
          style={{
            background:
              "radial-gradient(circle, #9D0DBB 15%, rgba(7, 2, 38, 0.7) 70%, #070226 100%)",
          }}
        ></div>
        <img
          src={box}
          alt="Box"
          className="absolute w-[1250px] h-[1250px] opacity-70 animate-float"
        />
      </div>

      {/* Overlay Gradient - Positioned from Middle of Box and Down */}
      <div
        className="absolute top-[750px] left-0 right-0 h-[1000px] pointer-events-none z-10"
        style={{
          background:
            "linear-gradient(to bottom, rgba(33, 25, 81, 0) 0%, #070226 30%)",
        }}
      ></div>

      {/* Hero Section */}
      <div className="relative w-full mt-[200px] pb-[600px] z-20">
        <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* CTA Content */}
          <div className="w-full flex flex-col items-center gap-[30px]">
            <img src={logoimg} className="h-[60px] sm:h-[100px] md:h-[120px] lg:h-[150px]" alt="Logo" />
            <h1 className="text-[#F0F3FF] text-[20px] sm:text-[22px] md:text-[24px] text-center px-5 w-full">
              <span className="text-[#15F5BA] font-bold">ExoCode</span> helps companies scale faster with custom-built SaaS products and tailored <img src={odooLogo} alt="Odoo" className="inline-block h-[20px] sm:h-[24px] md:h-[28px] mx-1 pb-[3px]" /> implementations — transforming operations and accelerating digital growth.
            </h1>
              <a 
              href="#works" 
              onClick={(e) => handleScroll(e, 'works')}
            >
               <button className="bg-[#15F5BA] text-[20px] sm:text-[22px] md:text-[24px] font-semibold py-[10px] px-[20px] rounded-[15px] transition duration-300 ease-in-out hover:shadow-[0_0_15px_5px_rgba(21,245,186,0.5)] active:bg-[#0CA67D] active:shadow-none">
                Explore Our Work
               </button>
              </a>
          </div>
        </div>
      </div>

      {/* Main Content Sections */}
      <OurServices />
      {/* <Numbers /> */}
      <OurWorks />

      <Why />
      <Contact />
      <Footer />
    </div>
  );
}
